import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography, Link, CircularProgress, Fade, useTheme } from '@mui/material';
import { getBiasColor } from '../utils/colorUtils';

const NewsColumn = ({ category, bias, edition, refreshKey }) => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const biasColor = getBiasColor(bias, theme);

  const categoryTitles = {
    'politics': 'News',
    'business-tech': 'Business & Tech',
    'entertainment-sports': 'Entertainment & Sports'
  };

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`/api/news?category=${category}&bias=${bias}&edition=${edition}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error('Error fetching news:', error);
        setError('Failed to load news. Please try again later.');
      }
      setLoading(false);
    };

    fetchNews();
  }, [category, bias, edition, refreshKey]);

  // Extract the source from the link
  const getSourceFromLink = (link) => {
    try {
      const url = new URL(link);
      return url.hostname.replace('www.', '');
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '';
    }
  };

  // Add the same decoder function
  const decodeHtmlEntities = (text) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography 
          color="error"
          sx={{ fontFamily: '"Georgia", "Times New Roman", serif' }}
        >
          {error}
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (news.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography 
          sx={{ fontFamily: '"Georgia", "Times New Roman", serif' }}
        >
          No articles found for this category and bias. Try adjusting the bias slider.
        </Typography>
      </Box>
    );
  }

  const renderArticle = (article, index) => {
    return (
      <Fade in={true} timeout={500} key={index}>
        <Card sx={{ 
          mb: 2, 
          overflow: 'hidden',
          transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: (theme) => theme.shadows[10],
          }
        }}>
          <CardContent sx={{ py: 1.5, px: 2, position: 'relative' }}>
            <Link 
              href={article.link} 
              underline="none"
              color="inherit"
            >
              <Typography 
                variant="subtitle1" 
                component="div"
                sx={{ 
                  fontFamily: '"Georgia", "Times New Roman", serif',
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                  transition: 'color 0.3s ease-in-out',
                  '&:hover': {
                    color: (theme) => theme.palette.mode === 'dark' ? 'primary.main' : 'primary.dark',
                  }
                }}
              >
                {article.flagEmoji} {decodeHtmlEntities(article.title)}
              </Typography>
            </Link>
            <Typography 
              variant="caption" 
              sx={{ 
                position: 'absolute', 
                bottom: 4, 
                right: 8, 
                color: 'text.secondary',
                fontFamily: '"Georgia", "Times New Roman", serif',
              }}
            >
              {getSourceFromLink(article.link)}
            </Typography>
          </CardContent>
        </Card>
      </Fade>
    );
  };

  return (
    <>
      <Box
        sx={{
          background: bias <= 40 ? 
            'linear-gradient(to right, #1565c0, #42a5f5)' : // Blue for liberal
            bias >= 60 ? 
              'linear-gradient(to right, #d84315, #8b0000)' : // Red for conservative
              'linear-gradient(to right, #757575, #424242)', // Grey for center
          color: 'white',
          p: 2,
          mb: 2,
          borderRadius: 1,
        }}
      >
        <Typography 
          variant="h5" 
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          {categoryTitles[category]}
        </Typography>
      </Box>
      {news.map(renderArticle)}
    </>
  );
};

export default NewsColumn;
