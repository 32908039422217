import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import { Container, Grid, Box, Typography } from '@mui/material';
import Header from './components/Header';
import BiasSlider from './components/BiasSlider';
import FlashOfTheDay from './components/FlashOfTheDay';
import NewsColumn from './components/NewsColumn';
import HeadlinesColumn from './components/HeadlinesColumn';
import Footer from './components/Footer';
import AdBox from './components/AdBox';
import { AD_POSITIONS } from './config/adsConfig';
import EditorPick from './components/EditorPick';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const isMobile = useMediaQuery('(max-width:600px)');
  const isPortrait = useMediaQuery('(orientation: portrait)');
  
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: prefersDarkMode ? '#90caf9' : '#1976d2',
          },
          secondary: {
            main: prefersDarkMode ? '#f48fb1' : '#dc004e',
          },
          background: {
            default: prefersDarkMode ? '#121212' : '#f5f5f5',
            paper: prefersDarkMode ? '#1e1e1e' : '#ffffff',
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h1: {
            fontWeight: 700,
            letterSpacing: '-0.01562em',
          },
          h2: {
            fontWeight: 600,
            letterSpacing: '-0.00833em',
          },
          h3: {
            fontWeight: 600,
            letterSpacing: '0em',
          },
          body1: {
            letterSpacing: '0.00938em',
          },
        },
        components: {
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 16,
                boxShadow: prefersDarkMode 
                  ? '0 8px 16px rgba(255,255,255,0.1)' 
                  : '0 8px 16px rgba(0,0,0,0.1)',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: prefersDarkMode 
                    ? '0 12px 24px rgba(255,255,255,0.15)' 
                    : '0 12px 24px rgba(0,0,0,0.15)',
                },
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 8,
                textTransform: 'none',
                fontWeight: 600,
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  const [bias, setBias] = useState(50);
  const [refreshKey, setRefreshKey] = useState(0);
  const [headlines, setHeadlines] = useState([]);
  const [flashOfDay, setFlashOfDay] = useState({ left: null, center: null, right: null });
  const [headlinesError, setHeadlinesError] = useState(null);
  const [flashError, setFlashError] = useState(null);
  const [lastCrawlTime, setLastCrawlTime] = useState(null);
  const [edition, setEdition] = useState(() => {
    const storedEdition = localStorage.getItem('preferredEdition');
    return storedEdition || 'US';
  });
  const [categoryArticles, setCategoryArticles] = useState({
    'politics': [],
    'business-tech': [],
    'entertainment-sports': []
  });
  const [editorPick, setEditorPick] = useState(null);
  const [editorPickError, setEditorPickError] = useState(null);

  const fetchFlashOfTheDay = useCallback(async () => {
    try {
      setFlashError(null);
      console.log('Fetching Flash of the Day for edition:', edition);
      const response = await fetch(`/api/flash?edition=${edition}`);
      console.log('Flash of the Day response:', response.status);
      
      if (!response.ok) {
        if (response.status === 404) {
          setFlashOfDay({ left: null, center: null, right: null });
          console.log('No Flash of the Day available (404)');
          return; // Don't treat this as an error, just empty state
        }
        
        // If we get a 503 (Service Unavailable), the server is working on updating the data
        // We should retry after a short delay
        if (response.status === 503) {
          console.log('Flash of the Day is being updated (503), will retry in 5 seconds');
          setTimeout(() => {
            console.log('Retrying Flash of the Day fetch after 503');
            fetchFlashOfTheDay();
          }, 5000);
          return;
        }
        
        throw new Error(`Failed to fetch Flash of the Day: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Flash of the Day data:', data);
      setFlashOfDay(data);
    } catch (error) {
      console.error('Error fetching Flash of the Day:', error);
      setFlashError('Failed to load Flash of the Day. Please try again later.');
      
      // If we hit an error, try again after a delay
      setTimeout(() => {
        console.log('Retrying Flash of the Day fetch after error');
        fetchFlashOfTheDay();
      }, 10000);
    }
  }, [edition]);

  useEffect(() => {
    fetchFlashOfTheDay();
  }, [fetchFlashOfTheDay]);

  useEffect(() => {
    let isSubscribed = true;
    const controller = new AbortController();

    const fetchLastCrawlTime = async () => {
      try {
        const response = await fetch('/api/last-crawl-time', {
          signal: controller.signal
        });
        const data = await response.json();
        if (isSubscribed) {
          setLastCrawlTime(data.lastCrawlTime);
        }
      } catch (error) {
        if (error.name === 'AbortError') return;
        console.error('Error fetching last crawl time:', error);
      }
    };
    
    fetchLastCrawlTime();
    const interval = setInterval(fetchLastCrawlTime, 60000);
    
    return () => {
      isSubscribed = false;
      controller.abort();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // Skip if user has already set a preference
    const storedEdition = localStorage.getItem('preferredEdition');
    if (storedEdition) {
      console.log('Using stored edition preference:', storedEdition);
      return;
    }

    // Get user's timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Detected timezone:', timezone);

    // European timezones start with 'Europe/' or include specific non-EU European countries
    const isEuropean = timezone.startsWith('Europe/') || 
                      timezone === 'GMT' ||
                      timezone.includes('London') || 
                      timezone.includes('Zurich') || 
                      timezone.includes('Oslo');
                      
    const newEdition = isEuropean ? 'EU' : 'US';
    
    console.log('Timezone check:', {
      timezone,
      isEuropean,
      newEdition,
      startsWithEurope: timezone.startsWith('Europe/'),
      includesLondon: timezone.includes('London'),
      includesZurich: timezone.includes('Zurich'),
      includesOslo: timezone.includes('Oslo')
    });
    
    setEdition(newEdition);
    localStorage.setItem('preferredEdition', newEdition);
  }, []); // Run once on mount

  const handleBiasChange = useCallback((newValue) => {
    setBias(newValue);
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  const handleResetBias = useCallback(() => {
    setBias(50);
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  const fetchHeadlines = useCallback(async () => {
    try {
      setHeadlinesError(null);
      
      // Track if we've successfully fetched headlines
      let headlinesFetched = false;
      let headlinesData = [];
      
      // Try to fetch today's headlines first
      try {
        const response = await fetch(`/api/headlines/today/${bias}/${edition}`);
        
        if (response.ok) {
          const data = await response.json();
          if (data && Array.isArray(data) && data.length > 0) {
            headlinesData = data;
            headlinesFetched = true;
            console.log('Successfully fetched today\'s headlines');
          }
        }
      } catch (error) {
        console.error('Error fetching today\'s headlines:', error);
      }
      
      // If today's headlines are not available, fall back to regular headlines
      if (!headlinesFetched) {
        console.log('Today\'s headlines not available, falling back to regular headlines');
        
        try {
          const response = await fetch(`/api/headlines/${bias}/${edition}`);
          
          if (response.ok) {
            const data = await response.json();
            if (data && Array.isArray(data)) {
              headlinesData = data;
              headlinesFetched = true;
              console.log('Successfully fetched regular headlines');
            }
          }
        } catch (error) {
          console.error('Error fetching regular headlines:', error);
        }
      }
      
      // If we still don't have headlines, try the other edition as a last resort
      if (!headlinesFetched || headlinesData.length === 0) {
        const otherEdition = edition === 'US' ? 'EU' : 'US';
        console.log(`No headlines available for ${edition} edition, trying ${otherEdition} edition as fallback`);
        
        try {
          const response = await fetch(`/api/headlines/${bias}/${otherEdition}`);
          
          if (response.ok) {
            const data = await response.json();
            if (data && Array.isArray(data)) {
              headlinesData = data;
              console.log(`Successfully fetched headlines from ${otherEdition} edition as fallback`);
            }
          }
        } catch (error) {
          console.error(`Error fetching headlines from ${otherEdition} edition:`, error);
        }
      }
      
      // Update state with whatever headlines we have (even if empty)
      setHeadlines(headlinesData);
      
      // If we have no headlines at all, set a more informative error
      if (headlinesData.length === 0) {
        console.log('No headlines available from any source');
        setHeadlinesError('Headlines are currently being updated. Please check back shortly.');
      }
    } catch (error) {
      console.error('Error in headlines fetching process:', error);
      setHeadlinesError('Failed to load headlines. Please try again later.');
    }
  }, [bias, edition]);

  useEffect(() => {
    fetchHeadlines();
  }, [fetchHeadlines, bias]);

  const fetchAllCategoryArticles = useCallback(async () => {
    try {
      const categories = ['politics', 'business-tech', 'entertainment-sports'];
      const articlesByCategory = {};
      
      await Promise.all(categories.map(async (category) => {
        try {
          const response = await fetch(`/api/news?category=${category}&bias=${bias}&edition=${edition}`);
          if (response.ok) {
            const data = await response.json();
            articlesByCategory[category] = data || [];
            console.log(`Fetched ${data.length} articles for ${category}`);
          } else {
            console.error(`Failed to fetch articles for ${category}: ${response.status}`);
            articlesByCategory[category] = [];
          }
        } catch (error) {
          console.error(`Error fetching ${category} articles:`, error);
          articlesByCategory[category] = [];
        }
      }));
      
      setCategoryArticles(articlesByCategory);
    } catch (error) {
      console.error('Error in fetchAllCategoryArticles:', error);
    }
  }, [bias, edition]);

  useEffect(() => {
    fetchAllCategoryArticles();
  }, [fetchAllCategoryArticles, bias, edition]);

  const fetchEditorPick = useCallback(async () => {
    try {
      setEditorPickError(null);
      const response = await fetch(`/api/editor-pick?edition=${edition}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        throw new Error("Received non-JSON response from server");
      }
      
      const data = await response.json();
      setEditorPick(data);
    } catch (error) {
      console.error('Error fetching editor pick:', error);
      setEditorPickError('Failed to load editor pick');
      setEditorPick(null);
    }
  }, [edition]);

  useEffect(() => {
    fetchEditorPick();
  }, [fetchEditorPick]);

  useEffect(() => {
    if (flashOfDay?.left?.imageUrl) {
      const img = new Image();
      img.src = flashOfDay.left.imageUrl;
    }
    if (editorPick?.imageUrl) {
      const img = new Image();
      img.src = editorPick.imageUrl;
    }
  }, [flashOfDay, editorPick]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container 
        maxWidth="xl" 
        sx={{ 
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '20px',
          mt: isMobile ? 
            (isPortrait ? '130px' : '80px') : 
            '80px'                            
        }}
      >
        <Header 
          lastCrawlTime={lastCrawlTime} 
          edition={edition}
          onEditionChange={setEdition}
        />
        
        <Box sx={{ my: 0 }} />
        
        <BiasSlider value={bias} onChange={handleBiasChange} />
        
        <Box sx={{ my: 6 }} />
        
        <Box sx={{ my: 6 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <FlashOfTheDay articles={flashOfDay} bias={bias} edition={edition} />
            </Grid>
            <Grid item xs={12} md={6}>
              {editorPickError ? (
                <Typography color="error">{editorPickError}</Typography>
              ) : (
                <EditorPick article={editorPick} edition={edition} />
              )}
            </Grid>
          </Grid>
        </Box>
        
        <Box sx={{ my: 6 }} />
        
        {headlinesError ? (
          <Typography color="error">{headlinesError}</Typography>
        ) : headlines.length > 0 ? (
          <HeadlinesColumn 
            headlines={headlines} 
            edition={edition} 
            bias={bias}
            categoryArticles={categoryArticles}
          />
        ) : (
          <Typography align="center">Loading headlines...</Typography>
        )}
        
        <Box sx={{ my: 4 }} />
        
        <AdBox position={AD_POSITIONS.TOP} />
        
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <NewsColumn category="politics" bias={bias} edition={edition} refreshKey={refreshKey} />
              </Grid>
              <Grid item xs={12} md={6}>
                <NewsColumn category="business-tech" bias={bias} edition={edition} refreshKey={refreshKey} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <NewsColumn category="entertainment-sports" bias={bias} edition={edition} refreshKey={refreshKey} />
          </Grid>
        </Grid>
        
        <AdBox position={AD_POSITIONS.MIDDLE} />
        
        <AdBox position={AD_POSITIONS.HEADLINES} />
        
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default App;
