import { ads } from './ads';

// Global ad manager to handle ad distribution
class AdManager {
  constructor() {
    this.usedIndexes = new Set();
    this.currentAds = new Map();
  }

  getAd(position) {
    // Remove old position if it exists
    if (this.currentAds.has(position)) {
      const oldIndex = this.currentAds.get(position);
      this.usedIndexes.delete(oldIndex);
    }

    // Get available indexes
    const availableIndexes = Array.from(
      { length: ads.length },
      (_, i) => i
    ).filter(i => !this.usedIndexes.has(i));

    // If no available indexes, reset everything
    if (availableIndexes.length === 0) {
      this.usedIndexes.clear();
      availableIndexes.push(...Array.from({ length: ads.length }, (_, i) => i));
    }

    // Select random available index
    const randomIndex = availableIndexes[Math.floor(Math.random() * availableIndexes.length)];
    
    // Mark as used and store position
    this.usedIndexes.add(randomIndex);
    this.currentAds.set(position, randomIndex);

    return ads[randomIndex];
  }

  removeAd(position) {
    if (this.currentAds.has(position)) {
      const index = this.currentAds.get(position);
      this.usedIndexes.delete(index);
      this.currentAds.delete(position);
    }
  }
}

export const adManager = new AdManager(); 