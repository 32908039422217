import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Card, CardContent, Link, Chip, List, ListItem, Avatar } from '@mui/material';
import AdBox from './AdBox';
import { AD_POSITIONS } from '../config/adsConfig';
import { getBiasColor } from '../utils/colorUtils';

const decodeHtmlEntities = (text) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};

// Format a timestamp to show how fresh an article is
const formatTimestamp = (timestamp) => {
  if (!timestamp) return '';
  
  const now = new Date();
  const articleDate = new Date(timestamp);
  const diffMinutes = Math.floor((now - articleDate) / (1000 * 60));
  
  if (diffMinutes < 60) {
    return `${diffMinutes} min ago`;
  } else if (diffMinutes < 24 * 60) {
    const hours = Math.floor(diffMinutes / 60);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    return articleDate.toLocaleDateString();
  }
};

// Update the sponsoredHeadline object
const sponsoredHeadline = {
  title: "Meet the Tech Company Leading AI-Powered Software & VFX Innovation",
  link: "https://11000ad.com/2025/03/19/ai-built-for-performance/",
  isSponsored: true
};

// Fallback headlines for when we don't have enough
const fallbackHeadlines = {
  politics: [
    {
      title: "Headlines are being updated with the latest news",
      link: "#",
      category: "politics",
      description: "Our system is currently gathering the latest political news from trusted sources.",
      isFallback: true
    },
    {
      title: "Stay tuned for political updates",
      link: "#",
      category: "politics",
      description: "We're refreshing our political news coverage. Check back soon for the latest headlines.",
      isFallback: true
    },
    {
      title: "Political news is being refreshed",
      link: "#",
      category: "politics",
      description: "Our news crawler is currently gathering fresh political content from across the web.",
      isFallback: true
    }
  ],
  'business-tech': [
    {
      title: "Business & Tech updates coming soon",
      link: "#",
      category: "business-tech",
      description: "We're currently refreshing our business and technology news coverage.",
      isFallback: true
    }
  ],
  'entertainment-sports': [
    {
      title: "Entertainment & Sports news being updated",
      link: "#",
      category: "entertainment-sports",
      description: "Stay tuned for the latest in entertainment and sports news.",
      isFallback: true
    }
  ]
};

// HeadlineItem component definition
const HeadlineItem = ({ headline, index, bias, isFallback }) => {
  const theme = useTheme();
  const biasColor = getBiasColor(bias, theme);
  
  // Extract the source from the link
  const getSourceFromLink = (link) => {
    if (link === '#') return '';
    
    try {
      const url = new URL(link);
      return url.hostname.replace('www.', '');
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '';
    }
  };

  // Determine if this is a special kind of headline
  const isArticleFallback = headline.isArticleFallback === true;
  const isFallbackHeadline = headline.isFallback === true;
  const isSponsored = headline.isSponsored === true;

  return (
    <ListItem 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start',
        padding: 2,
        borderBottom: `1px solid ${theme.palette.divider}`,
        opacity: isFallbackHeadline ? 0.7 : 1,
        // Add a subtle background for article fallbacks to distinguish them
        bgcolor: isArticleFallback ? `${biasColor}10` : 'transparent'
      }}
    >
      <Link
        href={headline.link}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
        sx={{ width: '100%' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
          <Box sx={{ mr: 2 }}>
            <Avatar 
              sx={{ 
                bgcolor: biasColor,
                width: 28,
                height: 28,
                fontSize: '0.875rem'
              }}
            >
              {index + 1}
            </Avatar>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography 
              variant="subtitle1" 
              component="h3"
              sx={{ 
                fontWeight: 'bold',
                color: theme.palette.text.primary,
                mb: 0.5
              }}
            >
              {decodeHtmlEntities(headline.title)}
            </Typography>
            
            {headline.description && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                {headline.description.length > 120 
                  ? `${decodeHtmlEntities(headline.description.substring(0, 120))}...` 
                  : decodeHtmlEntities(headline.description)
                }
              </Typography>
            )}
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {isSponsored ? (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                >
                  Advertisement
                </Typography>
              ) : isArticleFallback ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography 
                    variant="caption" 
                    color="text.secondary"
                  >
                    {getSourceFromLink(headline.link)}
                  </Typography>
                  <Chip 
                    label="Related" 
                    size="small" 
                    sx={{ 
                      ml: 1,
                      bgcolor: `${biasColor}30`,
                      color: biasColor,
                      fontSize: '0.7rem'
                    }} 
                  />
                </Box>
              ) : (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                >
                  {getSourceFromLink(headline.link)}
                </Typography>
              )}
              
              {headline.pubDate && !isFallbackHeadline && (
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                >
                  {formatTimestamp(headline.pubDate)}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    </ListItem>
  );
};

const HeadlinesColumn = ({ headlines, edition, bias, categoryArticles = {} }) => {
  const theme = useTheme();
  const biasColor = getBiasColor(bias, theme);

  console.log('Headlines received:', headlines);
  console.log('Category articles received:', categoryArticles);

  // Extract the source from the link
  const getSourceFromLink = (link) => {
    if (link === '#') return '';
    
    try {
      const url = new URL(link);
      return url.hostname.replace('www.', '');
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '';
    }
  };

  // Check if we have valid headlines data
  const hasValidHeadlines = headlines && Array.isArray(headlines) && headlines.length > 0;

  // If no headlines at all, show a complete fallback view
  if (!hasValidHeadlines) {
    console.log('No valid headlines data, checking article fallbacks');
    
    // Check if we have articles we can use as headlines
    const hasArticleFallbacks = categoryArticles && 
      Object.values(categoryArticles).some(articles => 
        Array.isArray(articles) && articles.length > 0
      );
    
    if (hasArticleFallbacks) {
      console.log('Using articles as headline fallbacks');
      
      // Create headline objects from articles
      const politicsFallbacks = (categoryArticles['politics'] || [])
        .slice(0, 3)
        .map((article, index) => ({
          ...article,
          uniqueId: `article-politics-${index}`,
          category: 'politics',
          isArticleFallback: true
        }));
      
      const businessTechFallbacks = (categoryArticles['business-tech'] || [])
        .slice(0, 1)
        .map((article, index) => ({
          ...article,
          uniqueId: `article-business-tech-${index}`,
          category: 'business-tech',
          isArticleFallback: true
        }));
      
      const entertainmentSportsFallbacks = (categoryArticles['entertainment-sports'] || [])
        .slice(0, 1)
        .map((article, index) => ({
          ...article,
          uniqueId: `article-entertainment-sports-${index}`,
          category: 'entertainment-sports',
          isArticleFallback: true
        }));
      
      // Create a complete set of headline fallbacks
      const articleFallbackHeadlines = [
        ...politicsFallbacks.slice(0, Math.min(3, politicsFallbacks.length)),
        ...businessTechFallbacks.slice(0, Math.min(1, businessTechFallbacks.length)),
        ...entertainmentSportsFallbacks.slice(0, Math.min(1, entertainmentSportsFallbacks.length))
      ];
      
      // If we have articles to use as headlines, render them
      if (articleFallbackHeadlines.length > 0) {
        return (
          <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}>
            <Typography 
              variant="h5" 
              component="h2" 
              gutterBottom 
              sx={{ 
                fontFamily: '"Georgia", "Times New Roman", serif',
                fontWeight: 'bold',
                mb: 3,
                color: 'white',
                textAlign: 'center', 
                width: '100%',
                mx: 'auto',
                p: 2,
                borderRadius: 1,
                background: bias <= 40 ? 
                  'linear-gradient(to right, #1565c0, #42a5f5)' : // Blue for liberal
                  bias >= 60 ? 
                    'linear-gradient(to right, #d84315, #8b0000)' : // Red for conservative
                    'linear-gradient(to right, #757575, #424242)' // Grey for center
              }}
            >
              Headlines
            </Typography>
            
            <AdBox position={AD_POSITIONS.HEADLINES} />
            
            <List sx={{ width: '100%' }}>
              {articleFallbackHeadlines.map((headline, index) => (
                <HeadlineItem 
                  key={headline.uniqueId || index}
                  headline={headline}
                  index={index}
                  bias={bias}
                  isFallback={false}
                />
              ))}
            </List>
          </Box>
        );
      }
    }
    
    // If no article fallbacks, show the generic fallback headlines
    console.log('No valid article fallbacks, showing generic fallback content');
    
    // Create a complete set of fallback headlines
    const fallbackPolitics = fallbackHeadlines.politics.map((headline, index) => ({
      ...headline,
      uniqueId: `fallback-politics-${index}`
    }));
    
    const fallbackBusinessTech = fallbackHeadlines['business-tech'].map((headline, index) => ({
      ...headline,
      uniqueId: `fallback-businessTech-${index}`
    }));
    
    const fallbackEntertainmentSports = fallbackHeadlines['entertainment-sports'].map((headline, index) => ({
      ...headline,
      uniqueId: `fallback-entertainmentSports-${index}`
    }));
    
    // Combine all fallback headlines
    const allFallbackHeadlines = [
      ...fallbackPolitics.slice(0, 3),
      ...fallbackBusinessTech.slice(0, 1),
      ...fallbackEntertainmentSports.slice(0, 1)
    ];
    
    // Render the fallback headlines
    return (
      <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}>
        <Typography 
          variant="h5" 
          component="h2" 
          gutterBottom 
          sx={{ 
            fontFamily: '"Georgia", "Times New Roman", serif',
            fontWeight: 'bold',
            mb: 3,
            color: 'white',
            textAlign: 'center', 
            width: '100%',
            mx: 'auto',
            p: 2,
            borderRadius: 1,
            background: bias <= 40 ? 
              'linear-gradient(to right, #1565c0, #42a5f5)' : // Blue for liberal
              bias >= 60 ? 
                'linear-gradient(to right, #d84315, #8b0000)' : // Red for conservative
                'linear-gradient(to right, #757575, #424242)' // Grey for center
          }}
        >
          Headlines
        </Typography>
        
        <AdBox position={AD_POSITIONS.HEADLINES} />
        
        <List sx={{ width: '100%' }}>
          {allFallbackHeadlines.map((headline, index) => (
            <HeadlineItem 
              key={headline.uniqueId || index}
              headline={headline}
              index={index}
              bias={bias}
              isFallback={true}
            />
          ))}
        </List>
      </Box>
    );
  }

  // Filter valid headlines
  const validHeadlines = headlines.filter(headline => headline && headline.link && headline.title);
  
  // Group headlines by category
  const politicsHeadlines = validHeadlines.filter(headline => headline.category === 'politics');
  const businessTechHeadlines = validHeadlines.filter(headline => headline.category === 'business-tech');
  const entertainmentSportsHeadlines = validHeadlines.filter(headline => headline.category === 'entertainment-sports');
  
  // Sort each category by timestamp (newest first)
  const sortByDate = (articles) => {
    return [...articles].sort((a, b) => {
      if (a.pubDate && b.pubDate) {
        return new Date(b.pubDate) - new Date(a.pubDate);
      }
      return 0;
    });
  };
  
  const sortedPolitics = sortByDate(politicsHeadlines);
  const sortedBusinessTech = sortByDate(businessTechHeadlines);
  const sortedEntertainmentSports = sortByDate(entertainmentSportsHeadlines);
  
  // Ensure we have the required number of headlines for each category
  const ensureHeadlines = (sortedHeadlines, count, category) => {
    const result = [...sortedHeadlines];
    
    // If we don't have enough headlines, first try to use other articles from the same category
    if (result.length < count) {
      // Find all available headlines in this category (including those we might already be using)
      let allCategoryHeadlines;
      if (category === 'politics') {
        allCategoryHeadlines = politicsHeadlines;
      } else if (category === 'business-tech') {
        allCategoryHeadlines = businessTechHeadlines;
      } else if (category === 'entertainment-sports') {
        allCategoryHeadlines = entertainmentSportsHeadlines;
      }

      // Filter out articles already in the result
      const unusedHeadlines = allCategoryHeadlines.filter(headline => 
        !result.some(r => r.link === headline.link)
      );

      // Add unused headlines as needed
      while (result.length < count && unusedHeadlines.length > 0) {
        const nextHeadline = unusedHeadlines.shift();
        result.push(nextHeadline);
      }
    }
    
    // If we still don't have enough, attempt to find articles from the general article section
    // This is the new logic to use articles instead of fallback headlines
    if (result.length < count) {
      // Look for articles from validHeadlines that may have been categorized differently
      // but could serve as a headline for this category
      const potentialFallbackArticles = validHeadlines.filter(article => 
        article.category !== category && // Different category
        !result.some(r => r.link === article.link) // Not already used
      );
      
      // Prioritize articles with a title that matches keywords for the category
      let keywordsForCategory = [];
      if (category === 'politics') {
        keywordsForCategory = ['politic', 'government', 'election', 'president', 'congress', 'senate', 'vote'];
      } else if (category === 'business-tech') {
        keywordsForCategory = ['business', 'tech', 'economy', 'market', 'company', 'startup', 'stock', 'finance', 'innovation'];
      } else if (category === 'entertainment-sports') {
        keywordsForCategory = ['entertainment', 'sport', 'movie', 'actor', 'music', 'game', 'celebrity', 'team', 'player'];
      }
      
      // Score articles by relevance to this category
      const scoredArticles = potentialFallbackArticles.map(article => {
        const title = article.title?.toLowerCase() || '';
        const description = article.description?.toLowerCase() || '';
        
        // Score based on keyword matches in title and description
        const score = keywordsForCategory.reduce((sum, keyword) => {
          const titleMatches = (title.match(new RegExp(keyword, 'g')) || []).length;
          const descMatches = (description.match(new RegExp(keyword, 'g')) || []).length;
          return sum + (titleMatches * 3) + descMatches; // Title matches are weighted 3x
        }, 0);
        
        return { ...article, relevanceScore: score };
      });
      
      // Sort by relevance score (highest first)
      scoredArticles.sort((a, b) => b.relevanceScore - a.relevanceScore);
      
      // Add relevant articles as needed
      for (const article of scoredArticles) {
        if (result.length >= count) break;
        
        // Create a headline-compatible object from the article
        const headlineFromArticle = {
          ...article,
          category: category, // Override with the current category
          isArticleFallback: true // Mark that this is an article serving as a headline
        };
        
        result.push(headlineFromArticle);
      }
    }
    
    // If we still don't have enough, check the articles from the category collection
    if (result.length < count && categoryArticles && categoryArticles[category] && categoryArticles[category].length > 0) {
      console.log(`Using ${category} articles as fallback for headlines`);
      
      // Filter out articles that are already in the result
      const unusedArticles = categoryArticles[category].filter(article => 
        !result.some(r => r.link === article.link)
      );
      
      // Add unused articles as needed
      for (let i = 0; i < unusedArticles.length && result.length < count; i++) {
        const articleFallback = {
          ...unusedArticles[i],
          category: category,
          isArticleFallback: true,
          uniqueId: `article-fallback-${category}-${i}`
        };
        result.push(articleFallback);
      }
    }
    
    // If we still don't have enough, only now use generic fallback headlines
    while (result.length < count) {
      // Get a fallback headline from the array, cycling through them if needed
      const fallbackIndex = result.length % fallbackHeadlines[category].length;
      const fallback = { ...fallbackHeadlines[category][fallbackIndex] };
      
      // Add a unique identifier to prevent React key warnings
      fallback.uniqueId = `fallback-${category}-${result.length}`;
      result.push(fallback);
    }
    
    return result.slice(0, count);
  };
  
  // Take the required number of headlines from each category, ensuring we have enough
  const selectedPolitics = ensureHeadlines(sortedPolitics, 3, 'politics');
  const selectedBusinessTech = ensureHeadlines(sortedBusinessTech, 1, 'business-tech');
  const selectedEntertainmentSports = ensureHeadlines(sortedEntertainmentSports, 1, 'entertainment-sports');
  
  // Combine headlines in the required order
  const arrangedHeadlines = [
    ...selectedPolitics,
    sponsoredHeadline,
    ...selectedBusinessTech,
    ...selectedEntertainmentSports
  ];

  return (
    <>
      <AdBox position={AD_POSITIONS.HEADLINES} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 4
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: '"Georgia", "Times New Roman", serif',
            fontWeight: 700,
            color: 'white',
            p: 2,
            mb: 2,
            borderRadius: 1,
            textAlign: 'center',
            width: '100%',
            mx: 'auto',
            background: bias <= 40 ? 
              'linear-gradient(to right, #1565c0, #42a5f5)' : // Blue for liberal
              bias >= 60 ? 
                'linear-gradient(to right, #d84315, #8b0000)' : // Red for conservative
                'linear-gradient(to right, #757575, #424242)' // Grey for center
          }}
        >
          Headlines
        </Typography>

        {arrangedHeadlines.length > 0 ? (
          arrangedHeadlines.map((headline, index) => (
            <Card 
              key={headline.uniqueId || `${headline.link}-${index}`}
              sx={{
                mb: 0.5,
                boxShadow: theme.palette.mode === 'dark' 
                  ? '0 4px 8px rgba(0,0,0,0.5)'
                  : '0 2px 6px rgba(0,0,0,0.1)',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: headline.isFallback ? 'none' : 'translateY(-3px)',
                  boxShadow: headline.isFallback ? (theme.palette.mode === 'dark' 
                    ? '0 4px 8px rgba(0,0,0,0.5)'
                    : '0 2px 6px rgba(0,0,0,0.1)') : 
                    (theme.palette.mode === 'dark' 
                      ? '0 6px 12px rgba(0,0,0,0.7)'
                      : '0 4px 10px rgba(0,0,0,0.2)'),
                  backgroundColor: headline.isFallback ? undefined : 
                                  theme.palette.mode === 'dark' ? 'rgba(50, 50, 50, 0.8)' : 'rgba(240, 247, 255, 0.8)'                  
                },
                position: 'relative',
                ...(headline.isSponsored && {
                  backgroundColor: theme.palette.mode === 'dark' ? '#1a2632' : '#e6f3ff',  // Pastel blue
                  borderLeft: '4px solid',
                  borderColor: theme.palette.mode === 'dark' ? '#3b82c4' : '#90caf9',
                  '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#1e2c3a' : '#f0f7ff',
                    transform: 'translateY(-3px)',
                    boxShadow: theme.palette.mode === 'dark' 
                      ? '0 6px 12px rgba(59,130,196,0.3)'
                      : '0 6px 12px rgba(144,202,249,0.3)'
                  }
                }),
                ...(headline.isFallback && {
                  opacity: 0.7,
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(30, 30, 30, 0.5)' : 'rgba(240, 240, 240, 0.5)'
                })
              }}
            >
              {headline.isFallback && (
                <Chip 
                  label="Archive" 
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    fontSize: '0.7rem',
                    height: '20px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#555555' : '#cccccc',
                    color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333'
                  }}
                />
              )}
              <CardContent>
                <Link
                  href={headline.link}
                  rel="noopener noreferrer"
                  underline="none"
                  sx={{
                    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                    '&:hover': {
                      color: headline.isSponsored ? 
                        (theme.palette.mode === 'dark' ? '#f48fb1' : '#c2185b') : 
                        (headline.isFallback ? 'inherit' : 
                          // Use fixed contrasting colors based on theme mode
                          theme.palette.mode === 'dark' ? biasColor : '#1976d2'
                        )
                    },
                    ...(headline.isFallback && {
                      cursor: 'default',
                      pointerEvents: 'none'
                    })
                  }}
                >
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      fontWeight: 600,
                      fontFamily: '"Georgia", "Times New Roman", serif',
                      mb: 0.5
                    }}
                  >
                    {decodeHtmlEntities(headline.title)}
                  </Typography>
                </Link>
                
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 1
                }}>
                  <Typography
                    variant="caption"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontSize: '0.75rem',
                      fontWeight: 500
                    }}
                  >
                    {headline.isSponsored ? 'Advertisement' : 
                     headline.isFallback ? 'No recent article available' : 
                     getSourceFromLink(headline.link)}
                  </Typography>
                  
                  {headline.pubDate && !headline.isSponsored && !headline.isFallback && (
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: '0.75rem',
                        fontStyle: 'italic'
                      }}
                    >
                      {formatTimestamp(headline.pubDate)}
                    </Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography align="center">
            No headlines available at the moment.
          </Typography>
        )}
      </Box>
    </>
  );
};

export default HeadlinesColumn;
