import React, { useState } from 'react';
import { Card, CardContent, Typography, Link, Box, CardMedia, useTheme, Chip } from '@mui/material';
import { getBiasColor } from '../utils/colorUtils';

// Format a timestamp to show how fresh an article is
const formatTimestamp = (timestamp) => {
  if (!timestamp) return '';
  
  const now = new Date();
  const articleDate = new Date(timestamp);
  const diffMinutes = Math.floor((now - articleDate) / (1000 * 60));
  
  if (diffMinutes < 60) {
    return `${diffMinutes} min ago`;
  } else if (diffMinutes < 24 * 60) {
    const hours = Math.floor(diffMinutes / 60);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    return articleDate.toLocaleDateString();
  }
};

const FlashOfTheDay = ({ articles, bias, edition }) => {
  const theme = useTheme();
  const biasColor = getBiasColor(bias, theme);  // Get dynamic bias color

  console.log('Flash of the Day article:', articles);

  // Enhanced selection logic to always show an article
  const getArticleForBias = (bias, articles) => {
    if (!articles || Object.keys(articles).length === 0) {
      return null;
    }
    
    // First, get all available articles and their timestamps
    const availableArticles = ['left', 'center', 'right']
      .filter(position => articles[position] && articles[position].title)
      .map(position => ({
        ...articles[position],
        position,
        biasMatch: (
          (bias <= 40 && position === 'left') ||
          (bias > 40 && bias < 60 && position === 'center') ||
          (bias >= 60 && position === 'right')
        )
      }))
      .sort((a, b) => {
        // First sort by bias match (true comes before false)
        if (a.biasMatch !== b.biasMatch) {
          return a.biasMatch ? -1 : 1;
        }
        // Then sort by timestamp (newer first)
        return new Date(b.pubDate) - new Date(a.pubDate);
      });

    // Return the best match (bias + freshness)
    return availableArticles[0] || null;
  };

  const article = getArticleForBias(bias, articles);

  // Add the decoder function
  const decodeHtmlEntities = (text) => {
    if (!text) return '';
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  // Function to create an excerpt from longer description
  const createExcerpt = (description, maxLength = 250) => {
    if (!description) return '';
    const decodedDescription = decodeHtmlEntities(description);
    
    if (decodedDescription.length <= maxLength) {
      return decodedDescription;
    }
    
    // Find the last space before the maxLength to avoid cutting words
    const lastSpaceIndex = decodedDescription.lastIndexOf(' ', maxLength);
    const excerpt = decodedDescription.substring(0, lastSpaceIndex > 0 ? lastSpaceIndex : maxLength);
    return `${excerpt}...`;
  };

  // Get source name from URL
  const getSourceFromLink = (link) => {
    try {
      if (!link || link === '#') return '';
      const url = new URL(link);
      return url.hostname.replace('www.', '');
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '';
    }
  };

  // Helper to extract image from description if needed
  const extractImageFromDescription = (description) => {
    if (!description) return null;
    const imgMatch = description.match(/<img[^>]+src="([^">]+)"/);
    return imgMatch ? imgMatch[1] : null;
  };

  // Fallback article to use when no real article is available
  const fallbackArticle = {
    title: "Today's headlines are currently being updated",
    link: "#",
    description: "Our system is currently processing the latest news. Please check back shortly for the latest Daily Flash article tailored to your bias preference.",
    isFallback: true,
    pubDate: new Date().toISOString()
  };

  // Use fallback if no article is available
  const displayArticle = article && article.title ? article : fallbackArticle;
  
  // Add this function near the top with other utility functions
  const cleanImageUrl = (url) => {
    if (!url) return null;
    // Fix doubled URLs
    if (url.includes('http') && url.indexOf('http', 1) !== -1) {
      return url.substring(url.lastIndexOf('http'));
    }
    return url;
  };

  // Then modify the getImageUrl function
  const getImageUrl = () => {
    if (!displayArticle || displayArticle.isFallback) return null;
    
    // If there's an imageUrl, clean and use it
    if (displayArticle.imageUrl) {
      const cleanedUrl = cleanImageUrl(displayArticle.imageUrl);
      if (cleanedUrl?.startsWith('http')) {
        return cleanedUrl;
      }
    }
    
    // Check for video thumbnail or embedded video
    if (displayArticle.description) {
      // Check for video iframe
      const videoMatch = displayArticle.description.match(/<iframe[^>]+src="([^">]+)"/);
      if (videoMatch) {
        // Get thumbnail from video URL (works for YouTube, Vimeo etc)
        const videoUrl = videoMatch[1];
        if (videoUrl.includes('youtube.com')) {
          const videoId = videoUrl.split('embed/')[1]?.split('?')[0];
          if (videoId) {
            return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
          }
        }
      }
      
      // Try to extract image from description as fallback
      const extractedImage = extractImageFromDescription(displayArticle.description);
      if (extractedImage) return cleanImageUrl(extractedImage);
    }
    
    return '/assets/nofotd.png';
  };
  
  const imageUrl = getImageUrl();

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      position: 'relative',
      boxShadow: theme.palette.mode === 'dark' ? '0 4px 20px rgba(0,0,0,0.4)' : '0 4px 20px rgba(0,0,0,0.15)',
      transition: 'transform 0.3s ease-in-out',
      maxWidth: 800,
      margin: '0 auto',
      overflow: 'hidden',
      '&:hover': {
        transform: displayArticle.isFallback ? 'none' : 'translateY(-6px)',
        boxShadow: theme.palette.mode === 'dark' ? '0 8px 30px rgba(0,0,0,0.6)' : '0 8px 30px rgba(0,0,0,0.2)'
      }
    }}>
      <Chip 
        label="Daily Flash" 
        color="primary"
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 10,
          fontWeight: 'bold',
          backgroundColor: biasColor.background
        }}
      />
      
      {!displayArticle.isFallback ? (
        <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
          <CardMedia
            component="img"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            image={imageUrl || '/assets/nofotd.png'}
            alt={displayArticle.title}
          />
        </Box>
      ) : (
        <Box 
          sx={{ 
            height: 120, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: biasColor.background,
            opacity: 0.7,
            color: '#fff'
          }}
        >
          <Typography variant="h5" component="div" align="center" sx={{ px: 2 }}>
            Daily Flash
          </Typography>
        </Box>
      )}
      
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Link 
          href={displayArticle.isFallback ? "#" : displayArticle.link}
          rel="noopener noreferrer"
          underline="none"
          sx={{ 
            color: 'inherit',
            cursor: displayArticle.isFallback ? 'default' : 'pointer'
          }}
          target="_blank"
        >
          <Typography 
            variant="h5" 
            component="div" 
            gutterBottom 
            sx={{ 
              fontFamily: '"Georgia", "Times New Roman", serif',
              fontWeight: 'bold',
              color: theme.palette.text.primary,
              lineHeight: 1.3,
              mb: 2,
              opacity: displayArticle.isFallback ? 0.8 : 1
            }}
          >
            {decodeHtmlEntities(displayArticle.title)}
          </Typography>
        </Link>
        
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ 
            mb: 2, 
            fontFamily: '"Georgia", "Times New Roman", serif',
            opacity: displayArticle.isFallback ? 0.8 : 1
          }}
        >
          {createExcerpt(displayArticle.description)}
        </Typography>
        
        <Box sx={{ 
          mt: 'auto', 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: `1px solid ${theme.palette.divider}`,
          pt: 2
        }}>
          <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 'medium' }}>
            {displayArticle.isFallback ? "FlashTopics" : getSourceFromLink(displayArticle.link)}
          </Typography>
          
          {displayArticle.pubDate && (
            <Typography variant="caption" color="text.secondary" sx={{ fontStyle: 'italic' }}>
              {formatTimestamp(displayArticle.pubDate)}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default FlashOfTheDay;
