export const ads = [
  {
    image: '/assets/ads/covenant_Leaderboard.png',
    url: 'https://store.steampowered.com/app/2207500/Covenant_Project_Zero/',
    alt: 'Covenant: Project Zero on Steam'
  },
  {
    image: '/assets/ads/marbletron_Leaderboard.png',
    url: 'https://apps.apple.com/us/app/marbletron/id6446699064',
    alt: 'Marbletron on App Store'
  },
  {
    image: '/assets/ads/plantera_Leaderboard.png',
    url: 'https://apps.apple.com/app/plantera/id6714471658',
    alt: 'Plantera on App Store'
  },
  {
    image: '/assets/ads/11000ad_Leaderboard.png',
    url: 'https://11000ad.com/2025/03/19/ai-built-for-performance/',
    alt: '11000AD - Future. Now.'
  }
]; 
