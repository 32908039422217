import React from 'react';
import { Card, CardContent, CardMedia, Typography, Link, Box, Chip, useTheme } from '@mui/material';

const EditorPick = ({ article }) => {
  const theme = useTheme();
  if (!article) return null;

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      position: 'relative',
      boxShadow: theme.palette.mode === 'dark' ? '0 4px 20px rgba(0,0,0,0.4)' : '0 4px 20px rgba(0,0,0,0.15)',
      transition: 'transform 0.3s ease-in-out',
      maxWidth: 800,
      margin: '0 auto',
      overflow: 'hidden',
      '&:hover': {
        transform: 'translateY(-6px)',
        boxShadow: theme.palette.mode === 'dark' ? '0 8px 30px rgba(0,0,0,0.6)' : '0 8px 30px rgba(0,0,0,0.2)'
      }
    }}>
      <Chip 
        label="Editor's Pick" 
        color="primary"
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 10,
          fontWeight: 'bold',
          backgroundColor: 'rgb(40, 110, 130)'
        }}
      />
      
      <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
        <CardMedia
          component="img"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          image={article.imageUrl || '/assets/nofotd.png'}
          alt={article.title}
        />
      </Box>
      
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Link 
          href={article.url}
          rel="noopener noreferrer"
          underline="none"
          sx={{ color: 'inherit' }}
          target="_blank"
        >
          <Typography 
            variant="h5" 
            component="div" 
            gutterBottom 
            sx={{ 
              fontFamily: '"Georgia", "Times New Roman", serif',
              fontWeight: 'bold',
              lineHeight: 1.3,
              mb: 2
            }}
          >
            {article.title}
          </Typography>
        </Link>
        
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ 
            mb: 2, 
            fontFamily: '"Georgia", "Times New Roman", serif'
          }}
        >
          {article.description}
        </Typography>
        
        <Box sx={{ 
          mt: 'auto', 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: `1px solid ${theme.palette.divider}`,
          pt: 2
        }}>
          <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 'medium' }}>
            {article.source}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EditorPick; 