// New utility file for color functions
export const getBiasColor = (bias, theme) => {
  // Liberal (blue) range
  if (bias <= 40) {
    return {
      background: 'linear-gradient(to right, #1565c0, #42a5f5)',
      color: 'white'
    };
  }
  // Conservative (red) range
  if (bias >= 60) {
    return {
      background: 'linear-gradient(to right, #d84315, #8b0000)',
      color: 'white'
    };
  }
  // Center (grey) range
  return {
    background: 'linear-gradient(to right, #757575, #424242)',
    color: 'white'
  };
}; 