import React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import VisitorCounter from './VisitorCounter';

function Footer() {
  const theme = useTheme();

  const links = [
    { text: "ABOUT US", href: "/about.html" },
    { text: "CONTACT US", href: "/contact.html" },
    { text: "PRIVACY POLICY", href: "/privacy-policy.html" },
    { text: "CALIFORNIA NOTICE", href: "/california-notice.html" },
    { text: "OPT-OUT CHOICES", href: "/opt-out-choices.html" },
    { text: "LEGAL", href: "/legal.html" }
  ];

  return (
    <Box sx={{ 
      py: 2, 
      mt: 'auto',
      textAlign: 'center' 
    }}>
      <VisitorCounter />
      <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <Link
              href={link.href}
              underline="hover"
              sx={{ 
                fontWeight: 'bold', 
                color: theme.palette.text.primary,
                ...(link.external && { target: "_blank", rel: "noopener noreferrer" })
              }}
            >
              {link.text}
            </Link>
            {index < links.length - 1 && (
              <Typography 
                component="span" 
                sx={{ 
                  mx: 1, 
                  color: theme.palette.text.secondary,
                  userSelect: 'none'  // Prevents selection of the divider
                }}
              >
                |
              </Typography>
            )}
          </React.Fragment>
        ))}
      </Typography>
    </Box>
  );
}

export default Footer;
