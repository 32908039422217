import { useState, useEffect } from 'react';
import { adManager } from '../config/adManager';
import './AdBox.css';

export default function AdBox({ position }) {
  const [currentAd, setCurrentAd] = useState(null);
  
  useEffect(() => {
    const ad = adManager.getAd(position);
    setCurrentAd(ad);

    return () => {
      adManager.removeAd(position);
    };
  }, [position]);

  if (!currentAd) return null;

  return (
    <div className="ad-container">
      <a href={currentAd.url} target="_blank" rel="noopener noreferrer" className="ad-link">
        <img src={currentAd.image} alt={currentAd.alt} loading="lazy" />
      </a>
    </div>
  );
}