import React, { useEffect } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Select, MenuItem } from '@mui/material';
import boltIcon from '../assets/images/BoltAlpha_128.png';
import { format } from 'date-fns';
import './Header.css';

const Header = ({ lastCrawlTime, edition, onEditionChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPortrait = useMediaQuery('(orientation: portrait)');
  
  const today = new Date();
  const formattedDate = format(today, 'MMMM d, yyyy');
  const formattedCrawlTime = lastCrawlTime 
    ? format(new Date(lastCrawlTime), 'MMM d, yyyy HH:mm')
    : 'Updating...';

  const handleEditionChange = (e) => {
    const newEdition = e.target.value;
    localStorage.setItem('preferredEdition', newEdition);
    onEditionChange(newEdition);
  };

  useEffect(() => {
    if (localStorage.getItem('preferredEdition')) {
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Timezone:', timezone);

    const isEuropean = timezone.startsWith('Europe/') || 
                      timezone.includes('London') || 
                      timezone.includes('Zurich') || 
                      timezone.includes('Oslo');
    const newEdition = isEuropean ? 'EU' : 'US';
    
    console.log('Setting edition to:', newEdition, 'based on timezone:', timezone);
    onEditionChange(newEdition);
    localStorage.setItem('preferredEdition', newEdition);
  }, [onEditionChange]);

  return (
    <header className="header">
      <div className={`header-content ${isMobile ? 'mobile' : ''} ${isPortrait ? 'portrait' : 'landscape'}`}>
        {isMobile && isPortrait ? (
          <>
            <div className="logo">
              <span className="logo-text">Flash</span>
              <img 
                src={boltIcon} 
                alt="Lightning Bolt" 
                className="logo-image"
              />
              <span className="logo-text">Topics</span>
            </div>
            <div className="info-row">
              <div className="edition-selector">
                <Select
                  value={edition || 'US'} // Provide default value
                  onChange={handleEditionChange}
                  className="edition-select"
                >
                  <MenuItem value="US">US Edition</MenuItem>
                  <MenuItem value="EU">EU Edition</MenuItem>
                </Select>
              </div>
              <div className="right-info">
                <div className="date">{formattedDate}</div>
                <div className="last-update">Updated: {formattedCrawlTime}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="edition-selector">
              <Select
                value={edition || 'US'} // Provide default value
                onChange={handleEditionChange}
                className="edition-select"
              >
                <MenuItem value="US">US Edition</MenuItem>
                <MenuItem value="EU">EU Edition</MenuItem>
              </Select>
            </div>
            
            <div className="logo">
              <span className="logo-text">Flash</span>
              <img 
                src={boltIcon} 
                alt="Lightning Bolt" 
                className="logo-image"
              />
              <span className="logo-text">Topics</span>
            </div>
            
            <div className="right-info">
              <div className="date">{formattedDate}</div>
              <div className="last-update">Updated: {formattedCrawlTime}</div>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
