export const AD_POSITIONS = {
  TOP: 'top',           // Above bias slider
  MIDDLE: 'middle',     // Above news columns
  HEADLINES: 'headlines' // Above headlines
};

export const AD_TYPES = {
  GOOGLE: 'google',
  CUSTOM: 'custom'
};

// This can be updated via API/backend when someone purchases an ad spot
export const defaultAdsConfig = {
  [AD_POSITIONS.TOP]: {
    type: AD_TYPES.GOOGLE,
    // Google Ads config
    googleAdUnit: 'ca-pub-XXXXX',
    googleAdSlot: 'SLOT1',
    // Custom ad config (used when type is CUSTOM)
    customAd: {
      imageUrl: '',
      link: '',
      altText: ''
    }
  },
  [AD_POSITIONS.MIDDLE]: {
    type: AD_TYPES.GOOGLE,
    googleAdUnit: 'ca-pub-XXXXX',
    googleAdSlot: 'SLOT2',
    customAd: {
      imageUrl: '',
      link: '',
      altText: ''
    }
  },
  [AD_POSITIONS.HEADLINES]: {
    type: AD_TYPES.GOOGLE,
    googleAdUnit: 'ca-pub-XXXXX',
    googleAdSlot: 'SLOT3',
    customAd: {
      imageUrl: '',
      link: '',
      altText: ''
    }
  }
}; 