import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

const VisitorCounter = () => {
  const [visitorCount, setVisitorCount] = useState(null);
  const today = new Date().toLocaleDateString('en-US', { 
    month: 'long', 
    day: 'numeric', 
    year: 'numeric' 
  });

  useEffect(() => {
    const hasVisited = sessionStorage.getItem('hasVisited');
    
    if (!hasVisited) {
      // First visit in this session - increment count
      fetch('/api/visitors/increment', { method: 'POST' })
        .then(res => res.json())
        .then(data => {
          if (data && typeof data.count === 'number') {
            setVisitorCount(data.count);
            sessionStorage.setItem('hasVisited', 'true');
          }
        })
        .catch(error => {
          console.error('Error incrementing visitor count:', error);
          setVisitorCount(0);
        });
    } else {
      // Just get current count without incrementing
      fetch('/api/visitors')
        .then(res => res.json())
        .then(data => {
          if (data && typeof data.count === 'number') {
            setVisitorCount(data.count);
          }
        })
        .catch(error => {
          console.error('Error fetching visitor count:', error);
          setVisitorCount(0);
        });
    }
  }, []);

  if (visitorCount === null) {
    return (
      <Typography 
        variant="body2" 
        align="center" 
        sx={{ 
          opacity: 0.7,
          mb: 1,
          fontSize: '0.85rem',
          fontFamily: 'monospace'
        }}
      >
        Loading visitor count...
      </Typography>
    );
  }

  return (
    <Typography 
      variant="body2" 
      align="center" 
      sx={{ 
        opacity: 0.7,
        mb: 1,
        fontSize: '0.85rem',
        fontFamily: 'monospace'
      }}
    >
      FLASHTOPICS.COM VISITS: {visitorCount !== null ? visitorCount.toLocaleString() : 'Loading...'}
    </Typography>
  );
};

export default VisitorCounter; 