import React from 'react';
import { Box, Typography, Slider, styled, useTheme, IconButton, Tooltip } from '@mui/material';

const StyledSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-track': {
    height: 8,
    borderRadius: 4,
    background: 'rgb(200, 200, 200)', /* Neutral gray color without gradient */
  },
  '& .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
    background: 'rgb(200, 200, 200)', /* Neutral gray color without gradient */
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 16,
    width: 4,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

const marks = [
  {
    value: 25,
    label: '',
  },
  {
    value: 50,
    label: '',
  },
  {
    value: 75,
    label: '',
  },
];

const BiasSlider = ({ value, onChange }) => {
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 500, margin: '0 auto' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        mb: 1
      }}>
        <Typography 
          id="bias-slider" 
          sx={{ 
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            color: 'text.secondary',
          }}
        >
          Bias Slider™
        </Typography>
        <Tooltip 
          title="Use the Bias Slider™ to shift your news political perspective."
          placement="bottom"
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          sx={{
            maxWidth: '90vw',
            textAlign: 'center'
          }}
        >
          <IconButton 
            size="small" 
            sx={{ 
              ml: 0.5, 
              p: 0,
              color: 'text.secondary',
            }}
            TouchRippleProps={{ center: true }}
          >
            <Box
              component="span"
              sx={{
                width: 14,
                height: 14,
                borderRadius: '50%',
                border: '2px solid currentColor',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '10px',
                fontWeight: 'bold',
                color: 'inherit',
              }}
            >
              i
            </Box>
          </IconButton>
        </Tooltip>
      </Box>

      <StyledSlider
        aria-label="Political Bias"
        value={value}
        onChange={handleChange}
        step={null}
        marks={marks}
        min={25}
        max={75}
      />

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        position: 'relative',
        mt: 0.5,
        px: 0
      }}>
        <Typography 
          variant="body2" 
          sx={{ 
            fontSize: '0.7rem',
            fontFamily: 'monospace'
          }}
        >
          Liberal
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '0.7rem',
            fontFamily: 'monospace'
          }}
        >
          Center
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            fontSize: '0.7rem',
            fontFamily: 'monospace'
          }}
        >
          Conservative
        </Typography>
      </Box>
    </Box>
  );
};

export default BiasSlider;
